<template>
  <v-dialog :value="value" class="dialog" persistent width="1300">
    <v-card color="#fafafa">
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Add Items</v-toolbar-title>
        <v-spacer />
        <v-col cols="2" align="right">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="mx-2" small fab color="orange" :disabled="emptyItem || copiedItem" height="40"
                @click="copyToClipboard">
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>Copy</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small fab :disabled="emptyItem || !copiedItem" height="40" color="red"
                @click="clearClipboard">
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </template>
            <span>Clear</span>
          </v-tooltip>
        </v-col>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row dense>
          <v-col cols="2">
            <v-text-field tabindex="1" ref="itemCode" dense background-color="#fff" height=20 outlined label="Item Code"
              @keydown.enter="getByItemCode" v-model="item.OrderCode" autocomplete="off">
              <template v-slot:append>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon small color="primary" @click="getByItemCode">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                  <span>Item Lookup</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field readonly dense background-color="#fff" outlined label="Pack" v-model="item.Pack" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field readonly dense background-color="#fff" outlined label="Size" v-model="item.Size" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field readonly dense background-color="#fff" outlined label="UPC" v-model="item.UPC" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field readonly dense background-color="#fff" outlined label="Item Description" v-model="item.Description"
              autocomplete="off">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <v-text-field tabindex="2" dense background-color="#fff" outlined type="number" step="0.01" min="0" prefix="$"
              label="Case Cost" v-model="item.CaseCost" @change="formatCurrencyInput('CaseCost', item.CaseCost)" autocomplete="off">
              <template v-slot:append>
                <v-btn-toggle dense mandatory v-model="appliedCaseCost">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small icon>
                        <v-icon small :color="appliedCaseCost ? 'green' : 'grey'">mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>{{appliedCaseCost === 0 ? 'Applied to Item Only' : 'Apply to Item'}}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small icon>
                        <v-icon small :color="!appliedCaseCost ? 'green' : 'grey'">mdi-check-all</v-icon>
                      </v-btn>
                    </template>
                    <span>{{appliedCaseCost === 1 ? 'Applied to Linked Items' : 'Apply to Linked Items'}}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="AMAP" v-model="item.AMAP" @change="formatCurrencyInput('AMAP', item.AMAP)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="Ad BB" v-model="item.AdBB" @change="formatCurrencyInput('AdBB', item.AdBB)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="TPR BB" v-model="item.TPRBB" @change="formatCurrencyInput('TPRBB', item.TPRBB)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="Ad Scan" v-model="item.AdScan" @change="formatCurrencyInput('AdScan', item.AdScan)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="TPR Scan" v-model="item.TPRScan" @change="formatCurrencyInput('TPRScan', item.TPRScan)" autocomplete="off">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="EBA" v-model="item.EBA" @change="formatCurrencyInput('EBA', item.EBA)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="ePay Ad" v-model="item.ePayAd" @change="formatCurrencyInput('ePayAd', item.ePayAd)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="ePay TPR" v-model="item.ePayTPR" @change="formatCurrencyInput('ePayTPR', item.ePayTPR)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              max="1" outlined label="Compete" v-model="item.Compete" @change="formatCurrencyInput('Compete', item.Compete)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="PCAF" v-model="item.PCAF" @change="formatCurrencyInput('PCAF', item.PCAF)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" outlined label="Deal ID" v-model="item.DealID"
              autocomplete="off">
          </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-divider/>
      <v-container class="ma-0">
        <v-data-table :loading="loading" :height="tableSize" :headers="headers" :items.sync="linkedItems" :search="search"
          :items-per-page="500" show-select v-model="selected" no-data-text="Enter Item Code to View Available Linked Group Items"
          item-key="Item.ItemCode" hide-default-footer dense fixed-header class="table">

          <template v-slot:top>
              <v-toolbar flat dense >
                <v-spacer/>
                <v-toolbar-title>Linked Group Items</v-toolbar-title>
                <v-spacer/>
              </v-toolbar>
              <v-divider/>
          </template>

          <template v-slot:[`item.Item.CaseCost`]="{ item }">
            {{formatCurrencyDisplay(item.Item.CaseCost)}}
          </template>

        </v-data-table>
      </v-container>
      <v-divider/>
      <v-toolbar flat color="#fafafa">
        <v-row justify="center">
        <v-col cols="3">
          <v-text-field :disabled="!linkedItems.length" outlined dense background-color="#fff" v-model="search"
          prepend-inner-icon="mdi-filter-outline" label="Filter Linked Group Items" />
        </v-col>
        <v-spacer/>
        <v-col v-if="selected.length" align="right">
          <span class="blue--text">
            <i>{{selected.length}} Selected</i>
          </span>
        </v-col>
        <v-spacer/>
        <v-col cols="4" align="right">
          <v-btn v-if="selected.length && appliedCaseCost !== 1" color="#0c67a5" height="40" class="white--text mx-2" @click="caseCostModal=true">
            Modify Case Cost<v-icon right>mdi-update</v-icon>
          </v-btn>
          <v-btn :disabled="emptyItem" color="#00B24A" height="40" class="white--text" @click="addItem">
            Add<v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        </v-row>
      </v-toolbar>
    </v-card>

    <v-dialog :value="caseCostModal" persistent width="400">
      <v-card>
        <v-toolbar dark flat color="#1C3144">
          <v-btn icon @click="caseCostModal=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Case Cost Update</v-toolbar-title>
        </v-toolbar>
        <v-container class="ma-0 pa-6">
          <v-row justify="center" dense>
            <v-col cols="6">
              <v-text-field outlined dense background-color="#fff" v-model="caseCostValue"
                @change="formatCurrencyInput('caseCostValue', caseCostValue)" label="Case Cost" prefix="$"/>
            </v-col>
          </v-row>
        </v-container>
        <v-divider/>
        <v-toolbar flat>
          <v-spacer/>
          <v-btn height="40" class="white--text" color="green" @click="updateCaseCost">
            Update<v-icon right>mdi-update</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'
import { cloneDeep, isEmpty } from 'lodash'

export default {
  name: 'ContractItemAdd',

  props: ['value', 'contract', 'getContract'],

  mixins: [tableSettings, notification],

  data () {
    return {
      appliedCaseCost: 0,
      caseCostModal: false,
      caseCostValue: '',
      clipboard: {},
      item: {},
      loading: null,
      linkedItems: [],
      pageSize: 500,
      search: '',
      selected: []
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Order Code', align: 'left', sortable: true, filterable: true, value: 'Item.OrderCode', class: 'black--text' },
        { text: 'Pack', align: 'left', sortable: true, filterable: true, value: 'Item.Pack', class: 'black--text' },
        { text: 'Size', align: 'left', sortable: true, filterable: true, value: 'Item.Size', class: 'black--text' },
        { text: 'UPC', align: 'left', sortable: true, filterable: true, value: 'Item.UPC', class: 'black--text' },
        { text: 'Description', align: 'left', sortable: true, filterable: true, value: 'Item.Description', class: 'black--text' },
        { text: 'Case Cost', align: 'left', sortable: true, filterable: true, value: 'Item.CaseCost', class: 'black--text' }
      ]
    },

    emptyItem () {
      return isEmpty(this.item)
    },

    copiedItem () {
      return !isEmpty(this.clipboard)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.linkedItems = []
          this.selected = []
          this.item = {}
          this.clipboard = {}
          this.errors = []
        }
      }
    },

    item: {
      immediate: true,
      deep: true,
      handler () {
        if (this.contract.ContractItems && this.contract.ContractItems.some(item => item.Item.OrderCode === this.item.OrderCode)) {
          this.notify('error', 'Item Already Exists On Contract')
          delete this.item.OrderCode
        }
      }
    },

    caseCostModal: {
      handler (value) {
        if (value) {
          this.caseCostValue = ''
        }
      }
    }
  },

  created () {
    this.nonTableHeight = 495
  },

  methods: {
    formatCurrencyInput (key, value) {
      if (key === 'caseCostValue') {
        this.caseCostValue = ((value * 100) / 100).toFixed(2)
      } else {
        this.item[key] = ((value * 100) / 100).toFixed(2)
      }
      this.$forceUpdate()
    },

    formatCurrencyDisplay (value) {
      return '$' + parseFloat(value).toFixed(2)
    },

    copyToClipboard () {
      this.clipboard = { ...this.item }
      this.notify('success', 'Item Copied')
    },

    clearClipboard () {
      this.clipboard = {}
      this.getByItemCode(this.item.OrderCode)
      this.notify('success', 'Clipboard Cleared')
    },

    async getByItemCode () {
      await this.$contracts.getByItemCode(this.item.OrderCode, this.contract.Supplier.ID)
        .then(response => {
          this.item.OrderCode = response[0].orderCode
          this.item.ItemCode = response[0].itemCode
          this.item.Pack = response[0].pack
          this.item.Size = response[0].size
          this.item.UPC = response[0].UPC
          this.item.Description = response[0].description
          this.item.CaseCost = isEmpty(this.clipboard) ? response[0].caseCost : this.clipboard.CaseCost
          this.item.AMAP = isEmpty(this.clipboard) ? '0.00' : this.clipboard.AMAP
          this.item.EBA = isEmpty(this.clipboard) ? '0.00' : this.clipboard.EBA
          this.item.AdBB = isEmpty(this.clipboard) ? '0.00' : this.clipboard.AdBB
          this.item.TPRBB = isEmpty(this.clipboard) ? '0.00' : this.clipboard.TPRBB
          this.item.AdScan = isEmpty(this.clipboard) ? '0.00' : this.clipboard.AdScan
          this.item.TPRScan = isEmpty(this.clipboard) ? '0.00' : this.clipboard.TPRScan
          this.item.ePayAd = isEmpty(this.clipboard) ? '0.00' : this.clipboard.ePayAd
          this.item.ePayTPR = isEmpty(this.clipboard) ? '0.00' : this.clipboard.ePayTPR
          this.item.Compete = isEmpty(this.clipboard) ? '0.00' : this.clipboard.Compete
          this.item.PCAF = isEmpty(this.clipboard) ? '0.00' : this.clipboard.PCAF
          this.item.DealID = isEmpty(this.clipboard) ? 'ADVRD1' : this.clipboard.DealID
          this.formatCurrencyInput('CaseCost', this.item.CaseCost)
          this.getLinkedGroups()
        }).catch(() => {
          this.notify('error', 'Item Not Available')
          this.item = {}
          this.linkedItems = []
        })
    },

    async getLinkedGroups () {
      this.loading = true
      await this.$contracts.getProductLinkGroup(this.item.OrderCode, this.contract.Supplier.ID)
        .then(response => {
          this.linkedItems = response.filter(x => x.orderCode !== this.item.OrderCode)
          this.linkedItems.forEach(linkedItem => {
            linkedItem.ContractID = this.contract.ContractID
            linkedItem.Item = {
              OrderCode: linkedItem.orderCode,
              ItemCode: linkedItem.itemCode,
              Pack: linkedItem.pack,
              Size: linkedItem.size,
              UPC: linkedItem.UPC,
              Description: linkedItem.description,
              CaseCost: linkedItem.caseCost
            }

            delete linkedItem.orderCode
            delete linkedItem.itemCode
            delete linkedItem.pack
            delete linkedItem.size
            delete linkedItem.UPC
            delete linkedItem.description
            delete linkedItem.caseCost
          })
        }).catch(() => {
        })
      this.loading = false
    },

    updateCaseCost () {
      this.linkedItems.forEach(linkedItem => {
        linkedItem.Item.CaseCost = this.caseCostValue
      })
      this.notify('success', 'Case Cost Successfully Updated')
      this.caseCostModal = false
    },

    async addItem () {
      this.item.ContractID = this.contract.ContractID

      this.item.Item = {
        OrderCode: this.item.OrderCode,
        ItemCode: this.item.ItemCode,
        Pack: this.item.Pack,
        Size: this.item.Size,
        UPC: this.item.UPC,
        Description: this.item.Description,
        CaseCost: this.item.CaseCost
      }

      delete this.item.OrderCode
      delete this.item.ItemCode
      delete this.item.Pack
      delete this.item.Size
      delete this.item.UPC
      delete this.item.Description
      delete this.item.CaseCost

      if (this.selected.length) {
        this.selected.push(this.item)

        const errors = []
        const promises = []

        this.selected.forEach(linkedItem => {
          if (this.appliedCaseCost === 1) {
            linkedItem.Item.CaseCost = this.item.Item.CaseCost
          }
          for (const a in this.item) {
            if (!Object.prototype.hasOwnProperty.call(a, linkedItem) && a !== 'Item') {
              linkedItem[a] = this.item[a]
            }
          }

          promises.push(
            this.$contracts.saveItem(linkedItem)
              .then().catch(() => {
                errors.push(`${linkedItem.Item.OrderCode} ${linkedItem.Item.Description} Not Added`)
              })
          )
        })

        await Promise.allSettled(promises)
        this.getContract()
        errors.length ? this.showErrors(errors) : this.notify('success', 'Items Added')
      } else {
        this.$contracts.saveItem(this.item)
          .then(() => {
            this.getContract()
            this.notify('success', 'Item Added')
            if (!isEmpty(this.clipboard)) {
              delete this.clipboard.OrderCode
              delete this.clipboard.ItemCode
              delete this.clipboard.Pack
              delete this.clipboard.Size
              delete this.clipboard.UPC
              delete this.clipboard.Description

              this.item = cloneDeep(this.clipboard)
            }
          }).catch(() => {
            this.notify('error', 'Error Adding Item')
          })
      }
      this.item = {}
      this.linkedItems = []
      this.selected = []
      this.appliedCaseCost = 0
      // this.$refs.itemCode.$el.focus()
    }
  }
}
</script>
<style scoped>
.v-expansion-panel >>> .v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>
